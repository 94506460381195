import { GetRegionByClientIPRequest, GetRegionsRequest, GetRegionAnnouncementsRequest } from '@winestyle/api-client/src/ts/api/region/v1/region_api_pb'

import type { RegionAPIPromiseClient } from '@winestyle/api-client/src/ts/api/region/v1/region_api_grpc_web_pb'

export const regionAPI = (regionAPIClient: RegionAPIPromiseClient) => {
  async function getRegions () {
    const request: GetRegionsRequest = new GetRegionsRequest()
    const res = await regionAPIClient.getRegions(request)

    return res?.getData?.()?.getRegionsList?.()?.map?.(p => p.toObject())
  }

  async function getRegionByClientIP (ip: string = '') {
    const request: GetRegionByClientIPRequest = new GetRegionByClientIPRequest()
    request.setIp(ip)

    const res = await regionAPIClient.getRegionByClientIP(request)
    return res?.getData()?.toObject()
  }
  async function getRegionAnnouncements () {
    const request: GetRegionAnnouncementsRequest = new GetRegionAnnouncementsRequest()

    const res = await regionAPIClient.getRegionAnnouncements(request)
    return res?.getData()?.toObject()?.announcementsList
  }

  return {
    getRegions,
    getRegionByClientIP,
    getRegionAnnouncements
  }
}

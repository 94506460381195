import { GetSupportInfoRequest } from '@winestyle/api-client/src/ts/api/marketing/v1/marketing_api_pb'

import type { MarketingAPIPromiseClient } from '@winestyle/api-client/src/ts/api/marketing/v1/marketing_api_grpc_web_pb'

export const marketingAPI = (marketingAPIClient: MarketingAPIPromiseClient) => {
  async function getSupportInfo () {
    const request: GetSupportInfoRequest = new GetSupportInfoRequest()
    const res = await marketingAPIClient.getSupportInfo(request)

    return res?.getData?.()?.toObject?.()
  }

  return { getSupportInfo }
}

import {
  AddProductToMyCartRequest,
  AddProductToMyCartResponse,
  DeselectItemsInMyCartRequest,
  DeselectItemsInMyCartResponse,
  EmptyMyCartRequest,
  EmptyMyCartResponse,
  GetMyCartRequest,
  GetMyCartBasicParametersRequest,
  GetMyCartBasicParametersResponse,
  GetMyCartResponse,
  RemoveItemsFromMyCartRequest,
  RemoveItemsFromMyCartResponse,
  SelectItemsInMyCartRequest,
  SelectItemsInMyCartResponse,
  SetItemQuantityInMyCartRequest,
  SetItemQuantityInMyCartResponse
} from '@winestyle/api-client/src/ts/api/sales/v1/cart_api_pb'

import type { CartAPIPromiseClient } from '@winestyle/api-client/src/ts/api/sales/v1/cart_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '@/modules/nuxt-api/models/type-helpers/reqTypeWithResponseCases'

export const cartAPI = (cartAPIClient: CartAPIPromiseClient) => {
  const getMyCart = async () => {
    const request: ReqTypeWithResponseCases<GetMyCartRequest> = new GetMyCartRequest()
    request.responseCases = GetMyCartResponse.ResponseCase
    const response = await cartAPIClient.getMyCart(request)

    return response.getData()?.getCart()?.toObject()
  }

  const getMyCartBasic = async () => {
    const request: ReqTypeWithResponseCases<GetMyCartBasicParametersRequest> = new GetMyCartBasicParametersRequest()
    request.responseCases = GetMyCartBasicParametersResponse.ResponseCase

    const response = await cartAPIClient.getMyCartBasicParameters(request)

    return response.getData()?.getCart()?.toObject()
  }

  const addProductToMyCart = async (productId: number, quantity: number, crossSellRuleId?: number) => {
    const request: ReqTypeWithResponseCases<AddProductToMyCartRequest> = new AddProductToMyCartRequest()
    request.responseCases = AddProductToMyCartResponse.ResponseCase

    request.setProductId(productId)
    request.setQuantity(quantity)
    crossSellRuleId && request.setCrossSellRuleId(crossSellRuleId)

    const response = await cartAPIClient.addProductToMyCart(request)

    if (response.getResponseCase() == AddProductToMyCartResponse.ResponseCase.VALIDATION_ERROR) {
      throw showError({ message: 'CSRF Error', statusCode: 500, fatal: true })
    }

    return response.getData()?.getCart()?.toObject()
  }

  const removeItemsFromMyCart = async (productIds: number[]) => {
    const request: ReqTypeWithResponseCases<RemoveItemsFromMyCartRequest> = new RemoveItemsFromMyCartRequest()

    request.responseCases = RemoveItemsFromMyCartResponse.ResponseCase
    request.setItemIdsList(productIds)

    const response = await cartAPIClient.removeItemsFromMyCart(request)

    if (response.getResponseCase() == RemoveItemsFromMyCartResponse.ResponseCase.VALIDATION_ERROR) {
      throw showError({ message: 'CSRF Error', statusCode: 500, fatal: true })
    }

    return response.getData()?.getCart()?.toObject()
  }

  const setItemQuantityInMyCart = async (productId: number, quantity: number) => {
    const request: ReqTypeWithResponseCases<SetItemQuantityInMyCartRequest> = new SetItemQuantityInMyCartRequest()

    request.responseCases = SetItemQuantityInMyCartResponse.ResponseCase
    request.setItemId(productId)
    request.setQuantity(quantity)

    const response = await cartAPIClient.setItemQuantityInMyCart(request)

    if (response.getResponseCase() == SetItemQuantityInMyCartResponse.ResponseCase.VALIDATION_ERROR) {
      throw showError({ message: 'CSRF Error', statusCode: 500, fatal: true })
    }

    return response.getData()?.getCart()?.toObject()
  }

  const emptyMyCart = async () => {
    const request: ReqTypeWithResponseCases<EmptyMyCartRequest> = new EmptyMyCartRequest()
    request.responseCases = EmptyMyCartResponse.ResponseCase

    const response = await cartAPIClient.emptyMyCart(request)

    return response.getData()?.getCart()?.toObject()
  }

  const selectItemsInMyCart = async (productIds: number[]) => {
    const request: ReqTypeWithResponseCases<SelectItemsInMyCartRequest> = new SelectItemsInMyCartRequest()

    request.responseCases = SelectItemsInMyCartResponse.ResponseCase
    request.setItemIdsList(productIds)

    const response = await cartAPIClient.selectItemsInMyCart(request)

    return response.getData()?.getCart()?.toObject()
  }

  const deselectItemsInMyCart = async (productIds: number[]) => {
    const request: ReqTypeWithResponseCases<DeselectItemsInMyCartRequest> = new DeselectItemsInMyCartRequest()

    request.responseCases = DeselectItemsInMyCartResponse.ResponseCase
    request.setItemIdsList(productIds)

    const response = await cartAPIClient.deselectItemsInMyCart(request)

    return response.getData()?.getCart()?.toObject()
  }

  return {
    getMyCart,
    getMyCartBasic,
    addProductToMyCart,
    removeItemsFromMyCart,
    setItemQuantityInMyCart,
    emptyMyCart,
    selectItemsInMyCart,
    deselectItemsInMyCart
  }
}

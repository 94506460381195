import {
  GetProductRequest,
  GetProductResponse,
  GetProductDetailsRequest,
  GetProductDetailsResponse,
  GetSimilarProductsRequest,
  GetProductMetadataRequest,
  GetProductMetadataResponse,
  GetProductAvailabilityRequest,
  GetProductDetailedAvailabilityRequest,
  GetProductDetailedAvailabilityResponse, GetProductWithDetailsResponse,
  GetProductBreadcrumbsResponse, GetProductBreadcrumbsRequest,
  GetProductVintagesExpertRatesRequest,
  GetProductVintagesExpertRatesResponse,
  GetProductOperationalDataResponse,
  GetProductOperationalDataRequest,

  GetProductShortForecastRequest
} from '@winestyle/api-client/src/ts/api/catalog/v1/product_api_pb.js'
import {
  GetProductCountForSectionRequest,
  GetProductCountForSectionResponse,
  GetProductsRequest,
  GetProductsResponse
} from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_pb.js'
import { SectionPath } from '@winestyle/api-client/src/ts/api/catalog/v1/common_pb.js'
import { PagePaginator } from '@winestyle/api-client/src/ts/api/shared/v1/common_pb'

import type { Product, ProductDetails } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type { ProductAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/product_api_grpc_web_pb'
import type { CatalogAPIPromiseClient } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_api_grpc_web_pb'
import type { ReqTypeWithResponseCases } from '../models/type-helpers/reqTypeWithResponseCases'

export const productAPI = (
  productAPIClient: ProductAPIPromiseClient,
  catalogAPIClient: CatalogAPIPromiseClient
) => {
  async function requireProduct (productCode: string, withDetails = false) {
    const request: ReqTypeWithResponseCases<GetProductRequest> = new GetProductRequest()

    request.setProductCode(productCode)
    request.responseCases = GetProductResponse.ResponseCase
    request.checkProductNotFound = true
    request.checkValidation = true

    const res = withDetails
      ? await productAPIClient.getProductWithDetails(request)
      : await productAPIClient.getProduct(request)

    const product = res.getData()?.getProduct()?.toObject() as Product.AsObject | undefined

    let details
    let replacement
    if (res instanceof GetProductWithDetailsResponse) {
      details = res.getData()?.getProductDetails()?.toObject() as ProductDetails.AsObject
      replacement = res.getData()?.getReplacement()?.toObject()
    }

    return { product, details, replacement }
  }

  async function requireProductDetails (productCode: string) {
    const request: ReqTypeWithResponseCases<GetProductDetailsRequest> = new GetProductDetailsRequest()

    request.setProductCode(productCode)
    request.responseCases = GetProductDetailsResponse.ResponseCase
    request.checkProductNotFound = true

    const res = await productAPIClient.getProductDetails(request)

    return res.getData()?.toObject()?.productDetails
  }

  async function getProductCountForSection (path: string) {
    const request: ReqTypeWithResponseCases<GetProductCountForSectionRequest> = new GetProductCountForSectionRequest()

    request.setSection(new SectionPath().setValue(path))
    request.responseCases = GetProductCountForSectionResponse.ResponseCase

    const res = await catalogAPIClient.getProductCountForSection(request)

    return res?.getData?.()?.getProductCount?.()
  }

  async function getBreadcrumbs (path: string) {
    const request: ReqTypeWithResponseCases<GetProductBreadcrumbsRequest> = new GetProductBreadcrumbsRequest()

    request.setProductCode(path)
    request.responseCases = GetProductBreadcrumbsResponse.ResponseCase
    request.checkProductNotFound = true

    const res = await productAPIClient.getProductBreadcrumbs(request)

    return res?.getData?.()?.getBreadcrumbsList?.()?.map?.(p => p.toObject())
  }

  async function getProducts (path = '', paginator: { page: number, perPage: number }, sortingCode = '', query = '') {
    const request: ReqTypeWithResponseCases<GetProductsRequest> = new GetProductsRequest()
    const paginatorReq = new PagePaginator()

    request.responseCases = GetProductsResponse.ResponseCase
    request.checkRedirect = true
    paginatorReq.setPage(paginator.page)
    paginatorReq.setItemsPerPage(paginator.perPage)

    request.setPaginator(paginatorReq)
    sortingCode && request.setSortingCodesList([sortingCode])

    if (query.length !== 0) {
      request.setQuery(query)
    }

    if (path.length !== 0) {
      const section = new SectionPath()
      section.setValue(path)
      request.setSection(section)
    }

    const res = await catalogAPIClient.getProducts(request)

    const products = res?.getData?.()?.getProductsList?.()?.map?.(p => p.toObject())
    const pagination = res?.getData?.()?.getPagination?.()?.toObject?.()
    const sorting = res?.getData?.()?.getCurrentSortingCode?.()

    return { products, pagination, sorting }
  }

  async function getSimilarProducts (code: string, limit: number = 20) {
    const request: GetSimilarProductsRequest = new GetSimilarProductsRequest()

    request.setProductCode(code)
    request.setLimit(limit)
    // request.responseCases = GetSimilarProductsResponse.ResponseCase
    // request.checkProductNotFound = true

    const res = await productAPIClient.getSimilarProducts(request)
    return res.getData()?.getProductsList?.()?.map(p => p.toObject())
  }

  async function getProductAvailability (idsList: number[]) {
    const request: GetProductAvailabilityRequest = new GetProductAvailabilityRequest()
    request.setProductIdsList(idsList)

    const res = await productAPIClient.getProductAvailability(request)

    return res?.getData?.()?.toObject?.()?.availabilitiesMap
  }

  async function getProductReceivingForecast (idsList: number[]) {
    const request: GetProductShortForecastRequest = new GetProductShortForecastRequest()
    request.setProductIdsList(idsList)

    const res = await productAPIClient.getProductShortForecast(request)

    return res?.getData?.()?.toObject?.()?.forecastsMap
  }

  async function getProductDetailedAvailability (productCode: string) {
    const request: ReqTypeWithResponseCases<GetProductDetailedAvailabilityRequest> = new GetProductDetailedAvailabilityRequest()

    request.setProductCode(productCode)
    request.responseCases = GetProductDetailedAvailabilityResponse.ResponseCase
    request.checkProductNotFound = true

    const res = await productAPIClient.getProductDetailedAvailability(request)

    return res?.getData?.()?.toObject?.()?.availabilityDetails
  }

  async function getProductMetadata (code: string) {
    const request: ReqTypeWithResponseCases<GetProductMetadataRequest> = new GetProductMetadataRequest()

    request.setProductCode(code)
    request.responseCases = GetProductMetadataResponse.ResponseCase
    request.checkProductNotFound = true
    request.checkValidation = true

    const res = await productAPIClient.getProductMetadata(request)

    return res?.getData?.()?.toObject?.()
  }

  async function getProductVintagesExpertRates (productCode: string) {
    const request: ReqTypeWithResponseCases<GetProductVintagesExpertRatesRequest> = new GetProductVintagesExpertRatesRequest()

    request.setProductCode(productCode)
    request.responseCases = GetProductVintagesExpertRatesResponse.ResponseCase
    request.checkProductNotFound = true
    request.checkValidation = true

    const res = await productAPIClient.getProductVintagesExpertRates(request)

    return res.getData()?.toObject()?.vintagesExpertRatesList
  }

  async function getProductOperationalData (idsList: number[]) {
    const request: ReqTypeWithResponseCases<GetProductOperationalDataRequest> = new GetProductOperationalDataRequest()

    request.setProductIdsList(idsList)
    request.responseCases = GetProductOperationalDataResponse.ResponseCase
    request.checkProductNotFound = true

    const res = await productAPIClient.getProductOperationalData(request)
    return res.getData()?.toObject()
  }

  return {
    requireProduct,
    requireProductDetails,
    getProductCountForSection,
    getProducts,
    getSimilarProducts,
    getProductAvailability,
    getProductReceivingForecast,
    getProductDetailedAvailability,
    getProductMetadata,
    getBreadcrumbs,
    getProductVintagesExpertRates,
    getProductOperationalData
  }
}
